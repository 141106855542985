import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  channel: null,
  defaultChannelState: {
    locationState: {},
    modalVisible: false,
    subActive: false
  }
};
const channelSlice = createSlice({
  name: "channel",
  initialState,
  reducers: {
    setCurrentChannel: (state, action) => {
      state.channel = { ...action.payload };
      state.defaultChannelState.subActive = false
    },
    setDefaultChannelState: (state, action) => {
      state.defaultChannelState = { ...action.payload };
    },
    toggleTicketModel: (state, action)=>{ 
      state.defaultChannelState.modalVisible = !state.defaultChannelState.modalVisible;
    },
    clearChannel: (state) => {
      state.channel = null;
    },
  },
});
export const { setCurrentChannel, clearChannel, setDefaultChannelState, toggleTicketModel } = channelSlice.actions;
export const getCurrentChannel = (state) => state.channel?.channel;
export const getDefaultChannelState = (state) => state.channel?.defaultChannelState;
export default channelSlice.reducer;
