import React, { useState, useEffect, useRef } from "react";
import {
  StatusBar,
  Text,
  Box,
  HStack,
  IconButton,
  Button,
  Center,
  FormControl,
  TextArea,
  Image,
  Heading,
  VStack,
  Card,
  AlertDialog,
  Spinner,
  Flex,
  ScrollView,
} from "native-base";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { faChevronLeft, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { getToken } from "../../store/slices/auth";
import { userService } from "../../_services/user.service";
import StarRating from "react-native-star-rating-widget";
import { Formik } from "formik";
import * as Yup from "yup";

const FeedbackStore = () => {
  const navigate = useNavigate();
  const token = useSelector(getToken);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [feedbackList, setFeedbackList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const onClose = () => setIsOpen(false);
  const cancelRef = useRef(null);

  const schema = Yup.object().shape({
    rating: Yup.number().min(1).max(5).required("Rating is required*"),
    feedback: Yup.string()
      .required("Comment is required*")
      .max(100, "Comment must be at most 100 characters"),
  });

  const storeFeed = async (values, { resetForm }) => {
    setButtonLoading(true);
    if (values.feedback == "") {
      return;
    }
    try {
      await userService.storeFeedback(token, values);
      resetForm();
      await getFeedback();
    } catch (error) {
      console.error("Error storing feedback:", error);
    } finally {
      setButtonLoading(false);
    }
  };

  const getFeedback = async () => {
    try {
      const response = await userService.getFeedback(token);
      setFeedbackList(response.data.data);
    } catch (error) {
      setFeedbackList([]);
      if (error.response && error.response.status === 404) {
        // setFeedbackList({ rating: null, feedback: null });
      } else {
        console.error("Error getting feedback:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFeedback();
  }, []);

  const handleDeleteFeedback = async () => {
    try {
      await userService.deleteFeedback(token);
      await getFeedback();
      console.log("Feedback deleted");
      onClose();
    } catch (error) {
      console.error("Error deleting feedback:", error);
    }
  };

  return (
    <>
      <StatusBar bg="#3700B3" />

      <Box safeAreaTop bg="#4f46e5" />

      <HStack
        bg="#4f46e5"
        px="1"
        py="3"
        justifyContent="space-between"
        alignItems="center"
        w="100%"
      >
        <HStack alignItems="center">
          <IconButton
            onPress={() => {
              navigate("/dashboard");
            }}
            _hover={{
              bg: "transparent",
            }}
            _pressed={{
              bg: "transparent",
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft} color="#FFF" />
          </IconButton>
          <Text color="white" fontSize="20" fontWeight="bold">
            Feedback
          </Text>
        </HStack>
      </HStack>

      <Flex
        direction={["column", "row", "row"]}
        alignItems="center"
        justifyContent="center"
        width={["100%", "100%", "100%"]}
        h="100%"
      >
        <Box
          space={4}
          width={["100%", "auto", "50%"]}
          h="100%"
          p={10}
          justifyContent={["start", "center", "center"]}
        >
          <Text borderRadius={5} padding={["0", "0", "10"]}>
            <Heading pb={5}>Your Feedback Is Important To Us</Heading>
            <Formik
              initialValues={{ rating: 0, feedback: "" }}
              onSubmit={storeFeed}
              validationSchema={schema}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                setFieldValue,
                errors,
                touched,
              }) => (
                <>
                  <FormControl>
                    <FormControl.Label pb={2}>Rating</FormControl.Label>
                    <StarRating
                      rating={values.rating}
                      onChange={(rating) => setFieldValue("rating", rating)}
                      starSize={40}
                      enableHalfStar={false}
                    />
                    {touched.rating && errors.rating && (
                      <Text color="red.500">{errors.rating}</Text>
                    )}
                  </FormControl>

                  <FormControl mt="3">
                    <FormControl.Label pb={2}>Comment</FormControl.Label>
                    <TextArea
                      onChangeText={handleChange("feedback")}
                      onBlur={handleBlur("feedback")}
                      value={values.feedback}
                      placeholder="Leave your comment"
                      placeholderTextColor="#fff"
                      borderColor="#fff"
                      minH="120"
                      style={{
                        fontSize: 15,
                        color: "#fff",
                      }}
                    />
                    {touched.feedback && errors.feedback && (
                      <Text color="red.500">{errors.feedback}</Text>
                    )}
                  </FormControl>

                  <Text textAlign="left">
                    <Button
                      backgroundColor="#4f46e5"
                      isLoading={buttonLoading}
                      onPress={() => handleSubmit()}
                      mt="4"
                      isDisabled={feedbackList.length != 0}
                    >
                      Share Your Feedback
                    </Button>
                  </Text>
                </>
              )}
            </Formik>
            {/* <Divider mt={8} w="100%"></Divider> */}

            <Center w={["100%", "100%", "100%"]}>
              {loading ? (
                <Spinner color="primary.500" />
              ) : (
                feedbackList &&
                feedbackList.length != 0 &&
                feedbackList.rating !== null &&
                feedbackList.feedback !== null && (
                  <Card bg="#4f46e570" mt={5} w={["100%", "100%", "600"]}>
                    <Flex direction="row" justifyContent="space-between">
                      <Text>
                        {""}
                        {feedbackList.feedback_time}
                      </Text>
                      <IconButton
                        _hover={{
                          bg: "transparent",
                        }}
                        _pressed={{
                          bg: "transparent",
                        }}
                        icon={
                          <FontAwesomeIcon icon={faTrashCan} color="#FFF" />
                        }
                        onPress={() => {
                          setIsOpen(true);
                        }}
                      />
                    </Flex>
                    <Heading fontSize={"xl"} mt={0}>
                      Your Feedback
                    </Heading>
                    <Box padding="0" pt={2}>
                      <HStack
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box width="100%">
                          <StarRating
                            rating={feedbackList.rating}
                            starSize={30}
                            enableHalfStar={false}
                            starStyle={{ width: 30, height: 30 }}
                            onChange={() => {}}
                          />
                          <Text width="100%" fontSize="md" mt={2}>
                            {feedbackList.feedback}
                          </Text>
                        </Box>
                      </HStack>
                    </Box>
                  </Card>
                )
              )}
            </Center>
          </Text>
        </Box>

        <Box p="5" w="50%" h="100%" display={["none", "block", "block"]}>
          <Image
            w="80%"
            h="90%"
            mx="auto"
            justifyContent="center"
            alignItems="center"
            safeAreaTop
            resizeMode="cover"
            source={{
              uri: `${`/assets/images/feedback.png`}`,
            }}
            fallbackSource={{
              uri: `/assets/images/feedback.png`,
            }}
          />
        </Box>
      </Flex>

      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Delete Feedback</AlertDialog.Header>
          <AlertDialog.Body>
            Are you sure you want to delete this feedback? This action cannot be
            undone.
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <Button ref={cancelRef} onPress={onClose}>
              Cancel
            </Button>
            <Button colorScheme="danger" onPress={handleDeleteFeedback} ml={3}>
              Delete
            </Button>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    </>
  );
};

export default FeedbackStore;
