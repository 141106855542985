import React, { useEffect, useState } from "react";
import { Formik, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  VStack,
  FormControl,
  Input,
  Button,
  Text,
  Box,
  Center,
  Stack,
  Heading,
  HStack,
  Link,
  Image,
} from "native-base";
import { authService } from "../../_services/auth.service";
import Styles from "../../assets/styles";
import { ImageBackground } from "react-native";
import * as yup from "yup";
import logo from "../../../src/assets/images/logo.png";

function PasswordForget() {
  const navigate = useNavigate();
  const token = window.location.hash.substring(1);
  const [password, setPassword] = useState("");

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await authService.verifyEmailToken({ token });
        console.log(response);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          alert(error.response.data.message);
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      }
    };

    verifyToken();
  }, [token, navigate]);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: yup.object().shape({
      password: yup
        .string()
        .required("Password is mandatory*")
        .min(8, "Password must be at least 8 characters long*")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Password must contain at least one uppercase letter, one lowercase letter, one special character, and one digit"
        ),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match*")
        .required("Confirm password is required*"),
    }),
    onSubmit: async (values) => {
      const updatePasswordData = {
        password: values.password,
        token: token,
      };

      try {
        const response = await authService.updatePassword(updatePasswordData);
        console.log(response.data.message);
        alert(response.data.message);
        navigate("/");
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.log(error.response.data.message);
        }
      }
    },
  });

  return (
    <>
      <Center flex={1} background="#3F53D8">
        <ImageBackground
          source={{
            uri: `${`/assets/images/welcome_bg.png`}`,
          }}
          fallbackSource={{
            uri: `/assets/images/welcome_bg.png`,
          }}
          resizeMode="cover"
          style={Styles.imageBg}
        >
          <Formik>
            <Stack
              space={5}
              w={["90%", "90%", "75%", "75%"]}
              maxW="400px"
              mx="auto"
              my="auto"
              style={{
                borderWidth: 1,
                borderColor: "thistle",
                borderRadius: 10,
                textAlign: "center",
                padding: 20,
                background: "#313338",
              }}
            >
              <Heading
                size="lg"
                fontWeight="600"
                color="coolGray.800"
                _dark={{
                  color: "warmGray.50",
                }}
                style={{ color: "#fff" }}
              >
                <Image source={logo} resizeMode="contain" size={160} />
                <br></br>
                Welcome
              </Heading>
              <Heading
                mt="1"
                _dark={{
                  color: "warmGray.200",
                }}
                color="coolGray.600"
                fontWeight="medium"
                size="xs"
                style={{ color: "#fff", opacity: "0.5" }}
              >
                Reset your password!
              </Heading>

              <FormControl
                isInvalid={formik.touched.password && formik.errors.password}
              >
                <FormControl.Label>Password</FormControl.Label>
                <Input
                  size="2xl"
                  variant="outline"
                  type="password"
                  value={formik.values.password}
                  onChangeText={formik.handleChange("password")}
                  onBlur={formik.handleBlur("password")}
                  placeholder="Enter Password"
                  style={{
                    fontSize: 15,
                    color: "#ffffff",
                    height: 47,
                    borderColor: "#ddd",
                    borderWidth: 1,
                  }}
                />
                <FormControl.ErrorMessage>
                  {formik.touched.password && formik.errors.password}
                </FormControl.ErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
              >
                <FormControl.Label>Confirm Password</FormControl.Label>
                <Input
                  size="2xl"
                  variant="outline"
                  type="password"
                  value={formik.values.confirmPassword}
                  onChangeText={formik.handleChange("confirmPassword")}
                  onBlur={formik.handleBlur("confirmPassword")}
                  placeholder="Confirm New Password"
                  style={{
                    fontSize: 15,
                    color: "#ffffff",
                    height: 47,
                    borderColor: "#ddd",
                    borderWidth: 1,
                  }}
                />
                <FormControl.ErrorMessage>
                  {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword}
                </FormControl.ErrorMessage>
              </FormControl>

              <Button
                type="submit"
                onPress={formik.handleSubmit}
                size="lg"
                style={{ background: "#3F53D8" }}
              >
                Update Password
              </Button>

              {/* <HStack mt="6" justifyContent="center">
                      <Text
                        fontSize="sm"
                        color="coolGray.600"
                        _dark={{
                          color: "warmGray.200",
                        }}
                        style={{ color: "#fff" }}
                      >
                        Already have an account ?{" "}
                      </Text>
                      <Link
                        to={"/sign-in"}
                        _text={{
                          fontWeight: "medium",
                          fontSize: "sm",
                        }}
                        href="#"
                      >
                        <Text color="#fff" underline>
                          Sign In
                        </Text>
                      </Link>
                    </HStack> */}
            </Stack>
          </Formik>
        </ImageBackground>
      </Center>
    </>
  );
}

export default PasswordForget;
