import axios from "axios";
export const serverService = {
  servers,
  follow,
  unfollow,
  isFollowingServer,
  subscription,
  faq,
  pages,
  getContent,
};
const API = process.env.REACT_APP_API_URL;

async function servers(token, page) {
  return await axios.get(`${API}/server/servers?page=${page}`, {
    headers: { Authorization: "Bearer " + token },
  });
}
async function follow(token, server) {
  return await axios.get(`${API}/server/follow?server=${server}`, {
    headers: { Authorization: "Bearer " + token },
  });
}
async function unfollow(token, server) {
  return await axios.get(`${API}/server/unfollow?server=${server}`, {
    headers: { Authorization: "Bearer " + token },
  });
}

async function isFollowingServer(token, server) {
  return await axios.get(`${API}/server/isFollowingServer?server=${server}`, {
    headers: { Authorization: "Bearer " + token },
  });
}

async function subscription(token) {
  return await axios.get(`${API}/payments/getSubscriptionPan`, {
    headers: { Authorization: "Bearer " + token },
  });
}

async function faq(token) {
  return await axios.get(`${API}/frontend/faqs`, {
    headers: { Authorization: "Bearer " + token },
  });
}

async function pages(token) {
  return await axios.get(`${API}/frontend/pages`, {
    headers: { Authorization: "Bearer " + token },
  });
}

async function getContent(token, slug) {
  return await axios.post(`${API}/frontend/pages/get-content?slug=${slug}`, {
    headers: { Authorization: "Bearer " + token },
  });
}
