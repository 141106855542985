/* eslint-disable no-unused-expressions */
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  loggedIn: false,
  _id: "",
  email: "",
  user: "",
  access: "",
  profile: {},
  payments: [],
  plan_name: "",
  about: "",
  phone: "",
  subscription_status: "inactive",
  email_verified_at: false,
  trial_status: "inactive",
  is_trial_period: false,
  timestamp: new Date().getTime(),
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.loggedIn = true;
      state._id = action.payload.uid;
      (state.email = action.payload.email),
        (state.user = action.payload.name),
        (state.about = action.payload.about),
        (state.phone = action.payload.phone),
        (state.access = action.payload.token),
        (state.profile = action.payload.profile);
      state.payments = action.payload.payments;
      (state.email_verified_at = action.payload.email_verified_at),
        (state.subscription_status = action.payload.subscription_status);
      state.trial_status = action.payload.trial_status;
      state.is_trial_period = action.payload.is_trial_period;
      state.plan_name = action.payload.plan_name;
    },
    logout: (state, action) => {
      state.loggedIn = false;
      (state._id = ""),
        (state.email = ""),
        (state.user = ""),
        (state.access = ""),
        (state.profile = {}),
        (state.timestamp = null);
      (state.email_verified_at = false),
        (state.trial_status = "inactive"),
        (state.subscription_status = "inactive"),
        (state.is_trial_period = false);
      state.payments = [];
      state.plan_name = "";
    },
    user: (state, action) => {
      state;
    },
    userProfileUpdate: (state, action) => {
      (state.profile = action.payload.profile),
        (state.user = action.payload.name),
        (state.about = action.payload.about),
        (state.phone = action.payload.phone);
    },
    subscription: (state, action) => {
      state.payments = action.payload.payments;
      state.trial_status = action.payload.trial_status;
      state.is_trial_period = action.payload.is_trial_period;
      state.subscription_status = action.payload.subscription_status;
      state.plan_name = action.payload.plan_name;
      //TODO::need to add plan name
    },
    cancelSubscription: (state, action) => {
      state.trial_status = "inactive";
      state.is_trial_period = false;
      state.subscription_status = "canceled";
    },
    subscriptionPause: (state, action) => {
      state.subscription_status = "paused";
      state.payments = action.payload;
    },
    subscriptionStatus: (state, action) => {
      state.payments = action.payload;
    },
  },
});
export const {
  login,
  logout,
  subscription,
  userProfileUpdate,
  cancelSubscription,
  subscriptionPause,
  subscriptionStatus,
} = authSlice.actions;
export const getAuth = (state) => state.auth;
export const getProfile = (state) => state.auth.profile;
export const getPayments = (state) => state.auth.payments;
export const getToken = (state) => state.auth.access;
export const isEmailVerified = (state) => state.auth.email_verified_at;
export const getTrialStatus = (state) => state.auth.trial_status;
export const getIsTrailPeriod = (state) => state.auth.is_trial_period;
export const isLoggedIn = (state) => state.auth.loggedIn;
export default authSlice.reducer;
