import { Stack, useColorMode } from "native-base";
import React from "react";
import Tabs from "../../pages/tab-comp";
const SiteLayout = ({ children }) => {
  const { colorMode } = useColorMode();
  return (
    <Stack
      flex={1}
      bg={colorMode === "light" ? "coolGray.50" : "coolGray.900"}
      style={{ background: "#ffffff" }}
    >
      {children}
      {/* <Tabs/> */}
    </Stack>
  );
};

export default SiteLayout;
