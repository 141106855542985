import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  servers: [],
};
const serversSlice = createSlice({
  name: "servers",
  initialState,
  reducers: {
    setServers: (state, action) => {
      state.servers = action.payload;
    },
    clearServers: (state) => {
      state.servers = [];
    },
    removeServerByUid: (state, action) => {
      const serverIdToRemove = action.payload;
      state.servers = state.servers.filter(
        (server) => server.uid !== serverIdToRemove
      );
    },
  },
});
export const { clearServers, setServers, removeServerByUid } =
  serversSlice.actions;
export const getServers = (state) => state.servers.servers;
export default serversSlice.reducer;
