export const _AuthGuard = (navigation, auth) => {
  if (
    auth?.payments?.length !== 0 &&
    (auth?.payments[0]?.status === "trialing" ||
      auth?.payments[0]?.status === "active")
  ) {
    navigation("/dashboard");
  } else {
    navigation("/pricing");
  }
};
