import axios from "axios";
export const messageService = {
  messages,
  createNewMessage,
  addReactionOnMessage,
  addBookmarkOnMessage,
  updateMessage,
  deleteMessage,
  uploadAttachments,
  downloadAttachment,
  messageScroll,
};
const API = process.env.REACT_APP_API_URL;

async function createNewMessage(token, message) {
  return await axios.post(
    `${API}/messages/store`,
    { ...message },
    { headers: { Authorization: "Bearer " + token } }
  );
}

async function addReactionOnMessage(token, pins) {
  return await axios.post(
    `${API}/messages/add-reaction`,
    { ...pins },
    { headers: { Authorization: "Bearer " + token } }
  );
}
async function addBookmarkOnMessage(token, reaction) {
  return await axios.post(
    `${API}/messages/add-bookmark`,
    { ...reaction },
    { headers: { Authorization: "Bearer " + token } }
  );
}
async function updateMessage(token, data) {
  return await axios.post(
    `${API}/messages/update`,
    { ...data },
    { headers: { Authorization: "Bearer " + token } }
  );
}
async function deleteMessage(token, data) {
  return await axios.post(
    `${API}/messages/delete`,
    { ...data },
    { headers: { Authorization: "Bearer " + token } }
  );
}
async function uploadAttachments(token, data, channel_code, server, channel) {
  return await axios.post(
    `${API}/messages/upload-media/${channel_code}/${server}/${channel}`,
    data,
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "multipart/form-data",
      },
    }
  );
}

async function downloadAttachment(token, data) {
  return await axios.post(`${API}/messages/download-chat-media`, data, {
    headers: { Authorization: "Bearer " + token },
  });
}
async function messageScroll(token, uid, pageNo) {
  return await axios.get(`${API}/messages?page=${pageNo}&channel=${uid}`, {
    headers: { Authorization: "Bearer " + token },
  });
}

async function messages(token, uid, id) {
  return await axios.get(`${API}/messages?channel=${uid}`, {
    headers: { Authorization: "Bearer " + token },
  });
}
