import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  server: null,
};
const serverSlice = createSlice({
  name: "server",
  initialState,
  reducers: {
    setCurrentServer: (state, action) => {
      state.server = { ...action.payload };
    },
    followServer: (state, action) => {
      if (state.server) {
        state.server.is_followed = action.payload.following;
        state.server.is_owner = action.payload.is_owner;
      }
    },
    unfollowServer: (state, action) => {
      if (state.server) {
        state.server.is_followed = action.payload.following;
        state.server.is_owner = action.payload.is_owner;
      }
    },
    clearServer: (state) => {
      state.server = null;
    },
  },
});
export const { setCurrentServer, unfollowServer, followServer, clearServer } =
  serverSlice.actions;
export const getCurrentServer = (state) => state.server?.server;
export default serverSlice.reducer;
