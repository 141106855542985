import { authService } from "../_services";
import { clearChannel } from "../store/slices/channel";
import { clearServer } from "../store/slices/server";
import { clearServers } from "../store/slices/servers";
import { showSuccessToast, showValidationErrorsToast } from "../_helpers";
import { Button, Center, Modal, Text, useToast } from "native-base";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAuth, logout } from "../store/slices/auth";

const DisableDashBoard = ({ setShowModal, showModal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector(getAuth);
  const toast = useToast();
  const handleClick = () => {
    navigate("pricing");
  };

  const handelLogout = async () => {
    try {
      dispatch(clearChannel());
      dispatch(clearServer());
      dispatch(clearServers());
      dispatch(logout());
      setTimeout(() => {
        navigate("/");
      }, 1500);
      const response = await authService.logout(auth?.access);
      if (response) {
        showSuccessToast("Logout Successfully!", toast);
      }
    } catch (error) {
      showValidationErrorsToast(error, toast);
    }
  };
  return (
    <Center>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        closeOnOverlayClick={false}
        size={"xl"}
      >
        <Modal.Content>
          <Modal.Header>Account Disable </Modal.Header>
          <Modal.Body>
            <Text>
              {" "}
              Your account is disabled because you don't have an active plan.{" "}
            </Text>
            <Text>
              {" "}
              Click here to go to the pricing page to purchase a plan.
            </Text>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button onPress={() => handelLogout()}> Logout</Button>
              <Button onPress={() => handleClick()}> Go to Pricing</Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </Center>
  );
};
export default DisableDashBoard;
