import { Stack, useColorMode } from "native-base";
import React from "react";
const AuthLayout = ({ children }) => {
  const { colorMode } = useColorMode();
  return (
    <Stack flex={1} bg={colorMode === "light" ? "coolGray.50" : "coolGray.900"}>
      {children}
    </Stack>
  );
};

export default AuthLayout;
