import { Button, Center, HStack, Heading, Stack, useToast } from "native-base";
import React from "react";
import { showSuccessToast, showInfoToast } from "../../_helpers";
import { useNavigate, useLocation } from "react-router-dom";

const InvitePeoplePage = () => {
  const [loading, setLoading] = React.useState(false);
  const [inviteCode, setInviteCode] = React.useState("");
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    setInviteCode(code);
  }, [location]);

  const handleAcceptPress = () => {
    showInfoToast("Sign-in first for accept invitation", toast);
    setTimeout(() => {
      showSuccessToast("Redirect to Sign-in page", toast);
    }, 2000);
    setTimeout(() => {
      navigate(`/Sign-in?code=${inviteCode}`);
    }, 4000);
  };

  return (
    <>
      <Center flex={1} bg="#111827">
        <Stack
          space={5}
          mt={2}
          w="75%"
          maxW="400px"
          mx="auto"
          style={{
            borderWidth: 1,
            borderColor: "thistle",
            borderRadius: 10,
            textAlign: "center",
            padding: 20,
            background: "#ffffff",
          }}
        >
          <Heading
            size="lg"
            fontWeight="600"
            color="coolGray.800"
            _dark={{
              color: "warmGray.50",
            }}
            style={{ color: "#000000" }}
          >
            Accept Invitation
          </Heading>
          <Heading
            mt="1"
            _dark={{
              color: "warmGray.200",
            }}
            color="coolGray.600"
            fontWeight="medium"
            size="xs"
            style={{ color: "#000000" }}
          >
            Click on accept now button for accept invitation
          </Heading>

          <HStack
            space={8}
            style={{ flex: 1, flexDirection: "column", flexWrap: "wrap" }}
          >
            <Button
              isLoading={loading}
              spinnerPlacement="end"
              size="lg"
              onPress={handleAcceptPress}
            >
              Accept Now
            </Button>
          </HStack>
        </Stack>
      </Center>
    </>
  );
};

export default InvitePeoplePage;
