import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { lazy, Suspense } from "react";
// import Tabs from "../pages/tab-comp";
import ProtectedRoutes from "./ProtectedRoutes";
// Layouts
import SiteLayout from "../components/Layouts/SiteLayout";
import InvitePeoplePage from "../pages/invitePeople";
import FeedbackStore from "../pages/feedback";
import AuthRoute from "./AuthRoutes";
import PasswordForget from "../pages/verifyPassword";
const WelcomePage = lazy(() => import("../pages/welcome"));
const FaqPage = lazy(() => import("../pages/faq"));
const HomePage = lazy(() => import("../pages/home"));
const PaymentPage = lazy(() => import("../pages/payment"));
const PricingPage = lazy(() => import("../pages/pricing"));
const SignInPage = lazy(() => import("../pages/sign-in"));
const SignUpPage = lazy(() => import("../pages/sign-up"));
const ForgotPage = lazy(() => import("../pages/forgot"));
const Tabs = lazy(() => import("../pages/tab-comp"));
const ContentPage = lazy(() => import("../pages/content"));
const SupportPage = lazy(() => import("../pages/support"));
const TicketSupport = lazy(() => import("../pages/ticket-support"));
const NotFound = lazy(() => import("../components/NotFound"));
// const Tabs = lazy(() => import("../pages/tabs"));

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <SiteLayout>
              <Suspense
                fallback={<div className="loading-loader-section"></div>}
              >
                <WelcomePage />
              </Suspense>
            </SiteLayout>
          }
        />
        {/* <Route
          exact
          path="/sign-up"
          element={
            <AuthRoute>
              <SiteLayout>
                <Suspense
                  fallback={<div className="loading-loader-section"></div>}
                >
                  <SignUpPage />
                </Suspense>
              </SiteLayout>
            </AuthRoute>
          }
        /> */}
        <Route
          exact
          path="/sign-in"
          element={
            <AuthRoute>
              <SiteLayout>
                <Suspense
                  fallback={<div className="loading-loader-section"></div>}
                >
                  <SignInPage />
                </Suspense>
              </SiteLayout>
            </AuthRoute>
          }
        />
        <Route
          exact
          path="/invite-people"
          element={
            <SiteLayout>
              <Suspense
                fallback={<div className="loading-loader-section"></div>}
              >
                <InvitePeoplePage />
              </Suspense>
            </SiteLayout>
          }
        />
        <Route
          exact
          path="/faq"
          element={
            <SiteLayout>
              <Suspense
                fallback={<div className="loading-loader-section"></div>}
              >
                <FaqPage />
              </Suspense>
            </SiteLayout>
          }
        />
        <Route element={<ProtectedRoutes />}>
          <Route path={`/pricing`} exact element={<PricingPage />} />
          <Route path={`/payment`} exact element={<PaymentPage />} />
          <Route path={`/dashboard`} exact element={<HomePage />} />
          <Route path={`/support`} exact element={<SupportPage />} />
          <Route path={`/ticket-support`} exact element={<TicketSupport />} />
          <Route path={`/feedback`} exact element={<FeedbackStore />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route
          path="/tabs"
          element={
            <SiteLayout>
              <Suspense
                fallback={<div className="loading-loader-section"></div>}
              >
                <Tabs />
              </Suspense>
            </SiteLayout>
          }
        />
        <Route
          path="/forgot"
          element={
            <SiteLayout>
              <Suspense
                fallback={<div className="loading-loader-section"></div>}
              >
                <ForgotPage />
              </Suspense>
            </SiteLayout>
          }
        />
        <Route
          path="/:slug"
          element={
            <SiteLayout>
              <Suspense
                fallback={<div className="loading-loader-section"></div>}
              >
                <ContentPage />
              </Suspense>
            </SiteLayout>
          }
        />
        <Route
          path="/reset-password"
          element={
            <SiteLayout>
              <Suspense
                fallback={<div className="loading-loader-section"></div>}
              >
                <PasswordForget />
              </Suspense>
            </SiteLayout>
          }
        />
        {/* <Route element={<ProtectedRoutes />}>
              <Route path={`/dashboard`} exact element={<DashboardPage />} />
        </Route> */}
      </Routes>
    </Router>
  );
};

export default AppRouter;
