import authSlice from "./auth";
import serverSlice from "./server";
import channelSlice from "./channel";
import serversSlice from "./servers";
import settingsSlice from "./settings";
import { combineReducers } from "@reduxjs/toolkit";

const rootReducer = combineReducers({
  auth: authSlice,
  server: serverSlice,
  channel: channelSlice,
  servers: serversSlice,
  settings: settingsSlice,
});

export default rootReducer;
