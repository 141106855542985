/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useContext } from "react";
import io from "socket.io-client";
import { getAuth } from "../store/slices/auth";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import {
  SOCKET_EVENTS,
  BY,
  LEVELS,
  TYPE,
  showAlertToast,
  showSocketToast,
  showValidationErrorsToast,
} from "../_helpers";
import { useToast } from "native-base";
import { getCurrentChannel, setCurrentChannel } from "../store/slices/channel";
import { getServers, setServers } from "../store/slices/servers";
import { setCurrentServer } from "../store/slices/server";
import { serverService } from "../_services";
export const SocketContext = createContext();
export default function SocketProvider({ children }) {
  const currentChannel = useSelector(getCurrentChannel);
  const servers = useSelector(getServers);
  const toast = useToast();
  const {
    DISCONNECT,
    CONNECT_ERROR,
    CONNECT,
    JOIN_CHANNEL,
    ADMIN_NOTIFICATION,
  } = SOCKET_EVENTS;
  const [socket, setSocket] = useState(null);
  const auth = useSelector(getAuth);
  const dispatch = useDispatch();
  React.useEffect(() => {
    //https://socket.edgestoinvest.com
    const socketInstance = io.connect("https://socket.edgestoinvest.com", {
      auth: {
        token: auth.access,
        uid: auth._id,
        username: auth.user,
      },
      transports: ["websocket", "polling"],
    });

    setSocket(socketInstance);
    socketInstance.on(CONNECT_ERROR, (error) => {
      // showSocketToast("Connecting to the Server...", toast, "rose.800");
      // document.title = "Connecting to the Server...";
    });
    socketInstance.on(DISCONNECT, (reason) => {
      console.log("Socket disconnected:", reason);
      // showSocketToast("Connecting to the Server...", toast, "rose.800");
    });
    socketInstance.on(CONNECT, () => {
      // showSocketToast("Server connected...", toast, "success.800");
      // document.title = "edgestoinvest";
      if (socketInstance) {
        if (currentChannel?.uid) {
          //Join new channel
          socketInstance.emit(JOIN_CHANNEL, {
            channelId: currentChannel?.channel_code,
            userId: auth?._id,
          });
        }
        /**
                 * Listen for admin notifications from the socket instance.
                If the notification is of level GLOBAL, type MESSAGE, and by EDITOR, and the server specified in the content is available in the list of servers,
                show an alert toast with the notification message.
                If the notification is of level GLOBAL, type UNASSIGN_USER_FROM_GROUP, and by EDITOR, and the user ID in the content matches the authenticated user's ID,
                fetch the servers again to update the server list and show an alert toast with the notification message.
                 */
        socketInstance.on(ADMIN_NOTIFICATION, async (response) => {
          const { level, message, type, content, by } = response;

          if (
            level === LEVELS.GLOBAL &&
            type === TYPE.MESSAGE &&
            by === BY.EDITOR
          ) {
            const isServerAvailable = servers.some(
              (server) => server.uid === content.server_id
            );

            if (isServerAvailable) {
              showAlertToast(
                { description: message, title: "Notification" },
                toast
              );
            }
          }
          if (
            level === LEVELS.GLOBAL &&
            type === TYPE.UNASSIGN_USER_FROM_GROUP &&
            by === BY.EDITOR &&
            auth?._id === content?.userId
          ) {
            fetchServers();
            const { message } = response;
            showAlertToast(
              { description: message, title: "Notification" },
              toast
            );
          }
        });
      }
    });
    return () => {
      if (socketInstance) {
        socketInstance.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.access]);
  /**
     *  Function to fetch servers on socket action. It fetches the servers using the serverService with the provided access token.
    It then sets the fetched servers in the state using dispatch(setServers(response.data.data.servers)).
    If there are servers fetched, it sets the current server to the first server in the list and resets the current channel.
    If there are no servers fetched, it resets both the current server and the current channel to empty objects.
    Any errors that occur during the fetch are caught and handled by showing validation errors in a toast message and logging the error.
     */
  const fetchServers = async () => {
    try {
      const response = await serverService.servers(auth?.access, 1);
      dispatch(setServers(response.data.data.servers));
      if (response.data.data.servers.length > 0) {
        dispatch(setCurrentServer(response.data.data.servers[0]));
        dispatch(setCurrentChannel({}));
      } else {
        dispatch(setCurrentServer({}));
        dispatch(setCurrentChannel({}));
      }
    } catch (error) {
      showValidationErrorsToast(error, toast);
      console.error(error, "error");
    }
  };
  const disconnectSocket = () => {
    if (socket) {
      socket.disconnect();
    }
  };

  return (
    <SocketContext.Provider value={{ socket, disconnectSocket }}>
      {children}
    </SocketContext.Provider>
  );
}
export const useSocketContext = () => useContext(SocketContext);
