import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import {} from "../store/slices/auth";

const useSubscriptionStatus = (payment) => {
  if (!payment || payment?.length === 0) {
    return;
  }
  const { status, trial_end, billing_cycle_anchor } = payment[0];

  const paymentNew = [];
  if (status && status === "trialing") {
    const trialEndTime = dayjs(trial_end);
    const currentTime = dayjs();
    if (currentTime.isAfter(trialEndTime)) {
      const updatedPayment = { ...payment[0], status: "active" };
      console.log(updatedPayment);
      paymentNew.push(updatedPayment);
      return { update: true, payment: paymentNew };
    }
  } else if (status === "paused") {
    // const billingTime = dayjs(billing_cycle_anchor);
    // const currentTime = dayjs();
    // if (currentTime.isAfter(billingTime)) {
    //   const updatedPayment = { ...payment[0], status: "ended" };
    //   console.log(updatedPayment);
    //   paymentNew.push(updatedPayment);
    //   return { update: true, payment: paymentNew };
    // }
  }

  return { update: false, payment: [] };
};

export default useSubscriptionStatus;
