import axios from "axios";
export const paymentService = {
  subscription,
  createCustomer,
  saveUserCardAndCreateSubscription,
  cancelSubscription,
  subscriptionPause,
  subscriptionResume,
};
const API = process.env.REACT_APP_API_URL;

async function subscription(token) {
  return await axios.get(`${API}/payments/getSubscriptionPan`, {
    headers: { Authorization: "Bearer " + token },
  });
}

async function createCustomer(token, data) {
  return await axios.post(`${API}/payments/create-customer`, data, {
    headers: { Authorization: "Bearer " + token },
  });
}

async function saveUserCardAndCreateSubscription(token, data) {
  return await axios.post(
    `${API}/payments/saveUserCardAndCreateSubscription`,
    data,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
}

async function cancelSubscription(token, data) {
  return await axios.post(`${API}/payments/cancelSubscription`, data, {
    headers: { Authorization: "Bearer " + token },
  });
}

async function subscriptionPause(token, data) {
  return await axios.post(`${API}/payments/subscriptionPause`, data, {
    headers: { Authorization: "Bearer " + token },
  });
}
async function subscriptionResume(token, data) {
  return await axios.post(`${API}/payments/subscriptionResume`, data, {
    headers: { Authorization: "Bearer " + token },
  });
}
