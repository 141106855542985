import axios from "axios";
export const authService = {
  login,
  register,
  resetPassword,
  logout,
  verifyEmailToken,
  updatePassword,
};
const API = process.env.REACT_APP_API_URL;
async function register(data) {
  return await axios.post(`${API}/auth/signUp`, data, {
    headers: { "Content-Type": "application/json" },
  });
}
async function login(data) {
  return await axios.post(`${API}/auth/signIn`, data, {
    headers: { "Content-Type": "application/json" },
  });
}
async function logout(token) {
  return await axios.post(`${API}/logout`, null, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

async function resetPassword(data) {
  return await axios.post(`${API}/auth/reset-password`, data, {
    headers: { "Content-Type": "application/json" },
  });
}

async function verifyEmailToken(data) {
  return await axios.post(`${API}/auth/verify-email-token`, data, {
    headers: { "Content-Type": "application/json" },
  });
}
async function updatePassword(data) {
  return await axios.post(`${API}/auth/update-password`, data, {
    headers: { "Content-Type": "application/json" },
  });
}
