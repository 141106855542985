import dayjs from "dayjs";

export const getTextFromHTMLString = (htmlString) => {
  return (
    document
      .createRange()
      // Creates a fragment and turns the supplied string into HTML nodes
      .createContextualFragment(htmlString)
      // Gets the text from the fragment
      .textContent // Removes the Zero-Width Space, Zero-Width Joiner, Zero-Width No-Break Space, Left-To-Right Mark, and Right-To-Left Mark characters
      .replace(/[\u200B-\u200D\uFEFF\u200E\u200F]/g, "")
      // Trims off any extra space on either end of the string
      .trim()
      .toString()
  );
};
export const getFileSize = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const getFirstLetter = (text) => {
  return text[0].toUpperCase();
};

export const formattedCreatedAt = (date) => {
  return dayjs(date).format("DD MMM YY, h:mm A");
};

export const getSubscription = (nextDate) => {
  const billingDate = dayjs(nextDate);
  const currentDate = dayjs().startOf("minute");
  if (billingDate <= currentDate) {
    return "Your Subscription is Expired";
  } else {
    return billingDate;
  }
};
