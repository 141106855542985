import React, { useEffect, useState } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AuthLayout from "../components/Layouts/AuthLayout";
import DisableDashBoard from "../components/DisableDashboard";
import {
  getToken,
  isLoggedIn,
  getPayments,
  getAuth,
  subscriptionStatus,
} from "../store/slices/auth";
import useSubscriptionStatus from "../_hooks/useSubscriptionStatus";

const ProtectedRoutes = () => {
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();

  const token = useSelector(getToken);
  const auth = useSelector(getAuth);
  const isUserLoggedIn = useSelector(isLoggedIn);
  const isPayment = useSelector(getPayments);

  const checkStatus = useSubscriptionStatus(isPayment);

  const isAuthenticated = isUserLoggedIn && token;
  const paymentStatus = isPayment && isPayment[0]?.status;

  // Update subscription status if needed
  useEffect(() => {
    if (checkStatus?.update) {
      dispatch(subscriptionStatus(checkStatus.payment));
    }
  }, [checkStatus, dispatch]);

  useEffect(() => {
    if (
      location.pathname === "/dashboard" &&
      (paymentStatus === "ended" || auth.subscription_status === "canceled")
    ) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [location, paymentStatus, auth.subscription_status]);

  if (
    location.pathname !== "/pricing" &&
    location.pathname !== "/payment" &&
    isPayment.length == 0
  ) {
    return <Navigate to="/pricing" replace />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return (
    <AuthLayout>
      <React.Suspense fallback={<div className="loading-loader-section"></div>}>
        <Outlet />
        <DisableDashBoard setShowModal={setShowModal} showModal={showModal} />
      </React.Suspense>
    </AuthLayout>
  );
};

export default ProtectedRoutes;
