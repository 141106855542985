import AppRouter from "./router";
import React from "react";
import { Text, HStack, Switch, useColorMode, useToast } from "native-base";
import { toastInit } from "./_helpers/toasts";
// import { Icon } from 'native-base';
// import { MaterialCommunityIcons } from '@expo/vector-icons'; // Assuming you're using Expo for vector icons
// import { MaterialIcons } from '@expo/vector- ';

const App = () => {
  const toast = useToast();
  React.useEffect(() => {
    toastInit(toast);
  }, []);
  const [selected, setSelected] = React.useState(1);
  return (
    <>
      <AppRouter />
    </>
  );
};
const ToggleDarkMode = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <HStack space={2}>
      <Text>Dark</Text>
      <Switch
        isChecked={colorMode === "light"}
        onToggle={toggleColorMode}
        accessibilityLabel={
          colorMode === "light" ? "switch to dark mode" : "switch to light mode"
        }
      />
      <Text>Light</Text>
    </HStack>
  );
};
export default App;

// <NativeBaseProvider>
// <Box flex={1} bg="white" safeAreaTop width="100%" maxW="300px" alignSelf="center">

//   <HStack bg="indigo.600" alignItems="center" safeAreaBottom shadow={6}>
//     <Pressable cursor="pointer" opacity={selected === 0 ? 1 : 0.5} py="3" flex={1} onPress={() => setSelected(0)}>
//       <Center>
//         {/* <Icon mb="1" as={<MaterialCommunityIcons name={selected === 0 ? 'home' : 'home-outline'} />} color="white" size="sm" /> */}
//         <Text color="white" fontSize="12">
//           Home
//         </Text>
//       </Center>
//     </Pressable>
//     <Pressable cursor="pointer" opacity={selected === 1 ? 1 : 0.5} py="2" flex={1} onPress={() => setSelected(1)}>
//       <Center>
//         {/* <Icon mb="1" as={<MaterialIcons name="search" />} color="white" size="sm" /> */}
//         <Text color="white" fontSize="12">
//           Message
//         </Text>
//       </Center>
//     </Pressable>
//     <Pressable cursor="pointer" opacity={selected === 3 ? 1 : 0.5} py="2" flex={1} onPress={() => setSelected(3)}>
//       <Center>
//         {/* <Icon mb="1" as={<MaterialCommunityIcons name={selected === 3 ? 'account' : 'account-outline'} />} color="white" size="sm" /> */}
//         <Text color="white" fontSize="12">
//           More
//         </Text>
//       </Center>
//     </Pressable>
//   </HStack>
// </Box>
// </NativeBaseProvider>;
