import {
  Alert,
  Box,
  CloseIcon,
  HStack,
  IconButton,
  Text,
  VStack,
} from "native-base";

let toastRef = "";

export const toastInit = (toast) => {
  toastRef = toast;
};

export const showValidationErrorsToast = (error, toast) => {
  toastRef.closeAll();
  console.log(error, "show");
  if (
    error.response &&
    error.response.data &&
    error.response.data.validation_error
  ) {
    const validationErrors = error.response.data.validation_error;
    Object.keys(validationErrors).forEach((key) => {
      validationErrors[key].forEach((errorMessage) => {
        toastRef.show({
          render: () => {
            return (
              <Box bg="red.500" px="5" py="3">
                {errorMessage}
              </Box>
            );
          },
          duration: 1000,
          placement: "top",
        });
      });
    });
  } else {
    let errorMessage = "An error occurred. Please try again.";
    if (error.response && error.response.data && error.response.data.message) {
      errorMessage = error.response.data.message;
    } else if (error.message) {
      errorMessage = error.message;
    }
    toastRef.show({
      render: () => {
        return (
          <Box bg="red.500" px="5" py="3">
            {errorMessage}
          </Box>
        );
      },
      duration: 1000,
      placement: "top",
    });
  }
};

export const showSuccessToast = (message, toast) => {
  toastRef.closeAll();
  toastRef.show({
    render: () => {
      return (
        <Box bg="green.500" px="5" py="3">
          {message}
        </Box>
      );
    },
    duration: 1000,
    placement: "top",
  });
};

export const showErrorToast = (message, toast) => {
  toastRef.closeAll();
  toastRef.show({
    render: () => {
      return (
        <Box bg="red.500" px="5" py="3">
          {message}
        </Box>
      );
    },
    duration: 1000,
    placement: "top",
  });
};

export const showInfoToast = (message, toast) => {
  toastRef.closeAll();
  toastRef.show({
    render: () => {
      return (
        <Box bg="blue.500" px="5" py="3">
          {message}
        </Box>
      );
    },
    duration: 2000,
    placement: "top",
  });
};

export const showSocketToast = (message, toast, bg) => {
  toastRef.closeAll();
  toastRef.show({
    render: () => {
      return (
        <Box bg={bg} px="5" py="3">
          {message}
        </Box>
      );
    },
    placement: "top",
  });
};

export const showAlertToast = (
  details,
  toast,
  variant = "solid",
  isClosable = true
) => {
  toastRef.closeAll();
  toastRef.show({
    render: () => {
      return (
        <Alert
          maxWidth="100%"
          alignSelf="center"
          flexDirection="row"
          status={"info"}
          variant={variant}
        >
          <VStack space={1} flexShrink={1} w="100%">
            <HStack
              flexShrink={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <HStack space={2} flexShrink={1} alignItems="center">
                <Alert.Icon />
                <Text
                  fontSize="md"
                  fontWeight="medium"
                  flexShrink={1}
                  color={
                    variant === "solid"
                      ? "lightText"
                      : variant !== "outline"
                      ? "darkText"
                      : null
                  }
                >
                  {details?.title}
                </Text>
              </HStack>
              {isClosable ? (
                <IconButton
                  variant="unstyled"
                  icon={<CloseIcon size="3" />}
                  _icon={{
                    color: variant === "solid" ? "lightText" : "darkText",
                  }}
                  onPress={() => toast.close()}
                />
              ) : null}
            </HStack>
            {details?.description && (
              <Text
                px="6"
                color={
                  variant === "solid"
                    ? "lightText"
                    : variant !== "outline"
                    ? "darkText"
                    : null
                }
              >
                {details?.description}
              </Text>
            )}
          </VStack>
        </Alert>
      );
    },
    placement: "top",
  });
};
