import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/global.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { NativeBaseProvider, extendTheme } from "native-base";
import SocketProvider from "./context/SocketContext";
const theme = extendTheme({
  config: {
    initialColorMode: "dark",
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <NativeBaseProvider theme={theme}>
        <SocketProvider>
          <App />
        </SocketProvider>
      </NativeBaseProvider>
    </PersistGate>
  </Provider>

  // </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
