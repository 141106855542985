import axios from "axios";
export const userService = {
  updateProfileAvatar,
  updateUserProfile,
  storeFeedback,
  getFeedback,
  deleteFeedback,
  storeDeviceToken,
  getUserNotifications,
  markNotificationAsSeen,
};
const API = process.env.REACT_APP_API_URL;

async function updateProfileAvatar(token, data) {
  return await axios.post(`${API}/user/updateProfileAvatar`, data, {
    headers: { Authorization: "Bearer " + token },
  });
}

async function updateUserProfile(token, data) {
  return await axios.put(`${API}/user/updateUserProfile`, data, {
    headers: { Authorization: "Bearer " + token },
  });
}
async function storeFeedback(token, data) {
  return await axios.post(`${API}/user/storeFeedback`, data, {
    headers: { Authorization: "Bearer " + token },
  });
}

async function getFeedback(token) {
  return await axios.get(`${API}/user/get-feedback`, {
    headers: { Authorization: "Bearer " + token },
  });
}
async function deleteFeedback(token, data) {
  return await axios.post(
    `${API}/user/delete-feedback`,
    { ...data },
    { headers: { Authorization: "Bearer " + token } }
  );
}
async function storeDeviceToken(token, data) {
  return await axios.post(`${API}/user/device-tokens`, data, {
    headers: { Authorization: "Bearer " + token },
  });
}
async function getUserNotifications(token) {
  return await axios.get(`${API}/user/getUserNotifications`, {
    headers: { Authorization: "Bearer " + token },
  });
}
async function markNotificationAsSeen(token, data) {
  return await axios.post(`${API}/user/mark-notification-seen`, data, {
    headers: { Authorization: "Bearer " + token },
  });
}