import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isChannelPanelClose: false,
};

const settings = createSlice({
  name: "settings",
  initialState,
  reducers: {
    toggleIsChannelPanelClose: (state) => {
      state.isChannelPanelClose = !state.isChannelPanelClose;
    },
  },
});

export const { toggleIsChannelPanelClose } = settings.actions;
export const getIsChannelPanelClose = (state) =>
  state.settings.isChannelPanelClose;
export default settings.reducer;
