import { StyleSheet, Dimensions } from "react-native";

const PRIMARY_COLOR = "#3f53d8";
const SECONDARY_COLOR = "#2b2d31";
const WHITE = "#FFFFFF";
const GRAY = "#757E90";
const DARK_GRAY = "#363636";
const BLACK = "#000000";

const ONLINE_STATUS = "#46A575";
const OFFLINE_STATUS = "#D04949";

const STAR_ACTIONS = "#FFA200";
const LIKE_ACTIONS = "#B644B2";
const DISLIKE_ACTIONS = "#363636";
const FLASH_ACTIONS = "#5028D7";
const MENU_COLOR = "#b5bac1";

const ICON_FONT = "tinderclone";

const DIMENSION_WIDTH = Dimensions.get("window").width - 350;
const DIMENSION_HEIGHT = Dimensions.get("window").height - 280;

const IMAGE_DIMENSION_WIDTH = Dimensions.get("window").width;
const IMAGE_DIMENSION_HEIGHT = Dimensions.get("window").height;

export default StyleSheet.create({
  // COMPONENT - CARD ITEM
  containerCardItem: {
    backgroundColor: WHITE,
    borderRadius: 8,
    alignItems: "center",
    margin: 10,
    shadowOpacity: 0.05,
    shadowRadius: 10,
    shadowColor: BLACK,
    shadowOffset: { height: 0, width: 0 },
  },
  matchesCardItem: {
    marginTop: -35,
    backgroundColor: PRIMARY_COLOR,
    paddingVertical: 7,
    paddingHorizontal: 20,
    borderRadius: 20,
  },
  matchesTextCardItem: {
    fontFamily: ICON_FONT,
    color: WHITE,
  },
  descriptionCardItem: {
    color: GRAY,
    textAlign: "center",
  },
  status: {
    paddingBottom: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  statusText: {
    color: GRAY,
    fontSize: 12,
  },
  online: {
    width: 10,
    height: 10,
    backgroundColor: ONLINE_STATUS,
    borderRadius: 100,
    marginRight: 4,
    position: "absolute",
    bottom: 13,
    left: 43,
    borderWidth: 1,
    borderColor: "#fff",
  },
  offline: {
    width: 6,
    height: 6,
    backgroundColor: OFFLINE_STATUS,
    borderRadius: 3,
    marginRight: 4,
  },
  overlayIcon: {
    position: "absolute",
  },
  actionsCardItem: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 30,
  },
  button: {
    width: 60,
    height: 60,
    borderRadius: 30,
    backgroundColor: WHITE,
    marginHorizontal: 7,
    alignItems: "center",
    justifyContent: "center",
    shadowOpacity: 0.15,
    shadowRadius: 20,
    shadowColor: DARK_GRAY,
    shadowOffset: { height: 10, width: 0 },
  },
  miniButton: {
    width: 40,
    height: 40,
    borderRadius: 30,
    backgroundColor: WHITE,
    marginHorizontal: 7,
    alignItems: "center",
    justifyContent: "center",
    shadowOpacity: 0.15,
    shadowRadius: 20,
    shadowColor: DARK_GRAY,
    shadowOffset: { height: 10, width: 0 },
  },
  star: {
    fontFamily: ICON_FONT,
    color: STAR_ACTIONS,
  },
  like: {
    fontSize: 25,
    fontFamily: ICON_FONT,
    color: LIKE_ACTIONS,
  },
  dislike: {
    fontSize: 25,
    fontFamily: ICON_FONT,
    color: DISLIKE_ACTIONS,
  },
  flash: {
    fontFamily: ICON_FONT,
    color: FLASH_ACTIONS,
  },

  // COMPONENT - CITY
  city: {
    backgroundColor: WHITE,
    padding: 10,
    borderRadius: 20,
    width: 90,
    shadowOpacity: 0.05,
    shadowRadius: 10,
    shadowColor: BLACK,
    shadowOffset: { height: 0, width: 0 },
  },
  cityText: {
    fontFamily: ICON_FONT,
    color: DARK_GRAY,
    fontSize: 13,
  },

  // COMPONENT - FILTERS
  filters: {
    backgroundColor: WHITE,
    padding: 10,
    borderRadius: 20,
    width: 70,
    shadowOpacity: 0.05,
    shadowRadius: 10,
    shadowColor: BLACK,
    shadowOffset: { height: 0, width: 0 },
  },
  filtersText: {
    fontFamily: ICON_FONT,
    color: DARK_GRAY,
    fontSize: 13,
  },

  // COMPONENT - MESSAGE
  containerMessage: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    paddingHorizontal: 10,
    width: DIMENSION_WIDTH - 50,
  },
  avatar: {
    borderRadius: 30,
    width: 40,
    height: 40,
    marginRight: 20,
    marginVertical: 15,
  },
  homeavatar: {
    borderRadius: 30,
    width: 24,
    height: 24,
    marginRight: 15,
    marginVertical: 10,
  },
  notfound: {
    borderRadius: 0,
    width: 200,
    height: 200,
    // marginRight: 20,
    marginVertical: 15,
  },
  message: {
    color: GRAY,
    fontSize: 14,
    paddingTop: 5,
  },
  messageright: {
    color: GRAY,
    fontSize: 14,
    paddingTop: 5,
    textAlign: "right",
    marginRight: 10,
  },
  TopActiveMenu: {
    color: WHITE,
    fontSize: 14,
    textAlign: "right",
    marginRight: 10,
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    columnGap: 5,
  },
  TopMenuItem: {
    color: MENU_COLOR,
    fontSize: 14,
    textAlign: "right",
    marginRight: 10,
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    columnGap: 5,
  },
  time: {
    color: GRAY,
    fontSize: 11,
    position: "relative",
    bottom: 1,
  },
  // COMPONENT - PROFILE ITEM
  containerProfileItem: {
    backgroundColor: WHITE,
    paddingHorizontal: 10,
    paddingBottom: 25,
    margin: 20,
    borderRadius: 8,
    marginTop: -65,
    shadowOpacity: 0.05,
    shadowRadius: 10,
    shadowColor: BLACK,
    shadowOffset: { height: 0, width: 0 },
  },
  matchesProfileItem: {
    width: 131,
    marginTop: -15,
    backgroundColor: PRIMARY_COLOR,
    paddingVertical: 7,
    paddingHorizontal: 20,
    borderRadius: 20,
    textAlign: "center",
    alignSelf: "center",
  },
  matchesTextProfileItem: {
    fontFamily: ICON_FONT,
    color: WHITE,
  },
  name: {
    paddingTop: 25,
    paddingBottom: 5,
    color: DARK_GRAY,
    fontSize: 15,
    textAlign: "center",
  },
  descriptionProfileItem: {
    color: GRAY,
    textAlign: "center",
    paddingBottom: 20,
    fontSize: 13,
  },
  info: {
    paddingVertical: 8,
    flexDirection: "row",
    alignItems: "center",
  },
  iconProfile: {
    fontFamily: ICON_FONT,
    fontSize: 12,
    color: DARK_GRAY,
    paddingHorizontal: 10,
  },
  infoContent: {
    color: GRAY,
    fontSize: 13,
  },

  // CONTAINER - GENERAL
  bg: {
    flex: 1,
    resizeMode: "cover",
    width: DIMENSION_WIDTH,
    height: DIMENSION_HEIGHT,
  },
  top: {
    paddingTop: 0,
    marginHorizontal: 0,
    height: 130,
    background: "#111827",
    width: "100%",
    justifyContent: "center",
  },
  headingtop: {
    paddingTop: 20,
    marginHorizontal: 10,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  innerheading: {
    paddingTop: 40,
    marginHorizontal: 0,
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
    fontSize: 20,
    color: DARK_GRAY,
    fontWeight: 600,
  },
  para: {
    marginTop: 10,
    color: WHITE,
    width: "200px", // set the width of the text container
    whiteSpace: "wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  desc: {
    marginTop: 10,
    color: WHITE,
    // width: '200px', // set the width of the text container
    whiteSpace: "wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  titleBox: {
    flexDirection: "column",
    lineHeight: 16,
    display: "flex",
    alignItems: "start",
    textAlign: "left",
    marginLeft: 20,
    justifyContent: "center",
  },
  titleBoxCenter: {
    flexDirection: "column",
    position: "absolute",
    left: 0,
    right: 0,
    lineHeight: 16,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(255, 255, 255, 0.06)",
    opacity: 0.5,
  },
  title: { paddingBottom: 0, fontSize: 22, color: WHITE, fontWeight: 600 },
  heading: {
    paddingBottom: 10,
    fontSize: 22,
    color: DARK_GRAY,
    fontWeight: 600,
    textAlign: "center",
  },
  icon: {
    fontFamily: ICON_FONT,
    fontSize: 20,
    color: DARK_GRAY,
    paddingRight: 10,
  },

  // CONTAINER - HOME
  containerHome: {
    marginHorizontal: 10,
    width: 767,
    margin: "auto",
    minHeight: "auto",
  },

  // CONTAINER - MATCHES
  containerMatches: {
    justifyContent: "space-between",
    flex: 1,
    paddingHorizontal: 10,
  },

  // CONTAINER - MESSAGES
  containerMessages: {
    justifyContent: "space-between",
    flex: 1,
    paddingHorizontal: 10,
    margin: "auto",
    minHeight: "auto",
  },

  containerMessagesleft: {
    justifyContent: "space-between",
    flex: 1,
    paddingHorizontal: 0,
    minHeight: "auto",
    marginBottom: 10,
    // marginTop: 10,
    zIndex: 1,
  },

  containerMessagesright: {
    justifyContent: "space-between",
    flex: 1,
    paddingHorizontal: 10,
    width: DIMENSION_WIDTH,
    minHeight: "auto",
    flexDirection: "row-reverse",
    alignItems: "center",
    // backgroundColor: '#ffffff',
    marginBottom: 0,
    justifyContent: "start",
    marginLeft: 0,
  },

  input: {
    height: 52,
    borderWidth: 1,
    padding: 10,
    backgroundColor: "#1e1f22",
    margin: "auto",
    textAlign: "left",
    borderRadius: 7,
    marginLeft: 0,
  },
  search: {
    height: 40,
    borderWidth: 1,
    padding: 10,
    backgroundColor: "#000000",
    margin: "auto",
    textAlign: "left",
    width: 220,
    color: WHITE,
    // width: DIMENSION_WIDTH,
  },
  bottomchat: {
    backgroundColor: "#262e35",
    borderRadius: 5,
    marginBottom: 0,
    // width: DIMENSION_WIDTH + 30,
    width: "100%",
    position: "relative",
    paddingLeft: 0,
  },
  Imagewidth: {
    width: "100%",
    height: IMAGE_DIMENSION_HEIGHT - 600,
    objectFit: "cover",
  },
  imageBg: {
    width: "100%",
    height: "100%",
  },
  fullwidth: {
    width: "auto",
    // height: "calc(100% - 180px)",
  },
  Innerfullwidth: {
    width: "100%",
    // height: DIMENSION_HEIGHT,
    margin: "auto",
    justifyContent: "center",
  },
  // CONTAINER - PROFILE
  containerProfile: { marginHorizontal: 0 },
  photo: {
    width: DIMENSION_WIDTH,
    height: 450,
  },
  topIconLeft: {
    fontFamily: ICON_FONT,
    fontSize: 20,
    color: WHITE,
    paddingLeft: 20,
    marginTop: -20,
    transform: [{ rotate: "90deg" }],
  },
  topIconRight: {
    fontFamily: ICON_FONT,
    fontSize: 20,
    color: WHITE,
    paddingRight: 20,
  },
  actionsProfile: {
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
  },
  iconButton: { fontFamily: ICON_FONT, fontSize: 20, color: WHITE },
  textButton: {
    fontFamily: ICON_FONT,
    fontSize: 15,
    color: WHITE,
    paddingLeft: 5,
  },
  circledButton: {
    width: 50,
    height: 50,
    borderRadius: 25,
    backgroundColor: PRIMARY_COLOR,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 10,
  },
  roundedButton: {
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 10,
    height: 50,
    borderRadius: 25,
    backgroundColor: SECONDARY_COLOR,
    paddingHorizontal: 20,
  },

  // MENU
  tabButton: {
    paddingTop: 20,
    paddingBottom: 30,
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  tabButtonText: {
    textTransform: "uppercase",
  },
  iconMenu: {
    fontFamily: ICON_FONT,
    height: 20,
    paddingBottom: 7,
  },

  /** home page **/
  welcomebg: {
    background: "/images/welcome-bg.png",
  },
  banner: {
    objectFit: "cover",
  },
  logoutBar: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    // backgroundColor: "rgb(30, 31, 34)",
  },
  options: {
    marginEnd: 10,
  },
  svg: {
    width: 100,
  },
});
