import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { getToken, isLoggedIn } from "../store/slices/auth";

const AuthRoute = ({ children }) => {
  const token = useSelector(getToken);
  const isUserLoggedIn = useSelector(isLoggedIn);
  const isAuthenticated = isUserLoggedIn && token;

  return isAuthenticated ? <Navigate to="/dashboard" replace /> : children;
};

export default AuthRoute;
