export const SOCKET_EVENTS = {
  JOIN_CHANNEL: "joinChannel",
  DISCONNECT: "disconnect",
  SEND_MESSAGE: "sendMessage",
  UPDATE_MESSAGE: "updateMessage",
  UPDATE_MESSAGE_REACTIONS: "updateMessageReactions",
  CONNECT_ERROR: "connect_error",
  CONNECT: "connect",
  ADMIN_NOTIFICATION: "admins_notification",
  DELETE_MESSAGE: "deleteMessage",
  TYPING: "typing",
  TYPING_RESPONSE: "typingResponse",
  STOP_TYPING: "stopTyping",
};

export const LEVELS = {
  GLOBAL: "global",
  CHANNEL: "channel",
};

export const TYPE = {
  MESSAGE: "MESSAGE",
  UNASSIGN_USER_FROM_GROUP: "UNASSIGN_USER_FROM_GROUP",
};

export const BY = {
  EDGE: "EDGE",
  EDITOR: "EDITOR",
  USER: "USER",
};
