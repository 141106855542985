import React, { useState, useEffect } from "react";
import { Text, HStack, Pressable, Box, Center } from "native-base";
import { Link, useLocation } from "react-router-dom";
import { MenuIcon, HomeIcon, MoreIcon } from "./MenuIcon";

const Tabs = () => {
  const [hideTabs, setHideTabs] = useState(false); // State to control whether to hide tabs or not
  const location = useLocation(); // Using useLocation hook to get current URL path

  useEffect(() => {
    // Check if the current path is /sign-in or /sign-up
    if (
      location.pathname === "/sign-in" ||
      location.pathname === "/sign-up" ||
      location.pathname === "/forgot"
    ) {
      setHideTabs(true); // Hide tabs if the path is /sign-in or /sign-up
    } else {
      setHideTabs(false); // Show tabs for other paths
    }
  }, [location.pathname]); // Run the effect whenever location.pathname changes

  if (hideTabs) {
    return null; // Don't render anything if hideTabs is true
  }

  return (
    <Box
      flex={1}
      bg="transparent"
      safeAreaTop
      width="100%"
      maxW="767px"
      maxH="66px"
      alignSelf="center"
      style={{ justifyContent: "end" }}
    >
      <HStack alignItems="center" safeAreaBottom shadow={6} bg="#1f8ad0">
        <Pressable
          cursor="pointer"
          opacity={location.pathname === "/" ? 1 : 0.5}
          py="3"
          flex={1}
        >
          <Center>
            <Link to="/" style={{ textDecoration: "none" }}>
              <Text color="white" fontSize="12">
                <MenuIcon />
                Home
              </Text>
            </Link>
          </Center>
        </Pressable>
        <Pressable
          cursor="pointer"
          opacity={location.pathname === "/message" ? 1 : 0.5}
          py="2"
          flex={1}
        >
          <Center>
            <Link to="/message" style={{ textDecoration: "none" }}>
              <Text color="white" fontSize="12">
                <HomeIcon />
                Message
              </Text>
            </Link>
          </Center>
        </Pressable>
        <Pressable
          cursor="pointer"
          opacity={location.pathname === "/" ? 1 : 0.5}
          py="2"
          flex={1}
        >
          <Center style={{ textAlign: "center" }}>
            <Link to="/" style={{ textDecoration: "none" }}>
              <Text color="white" fontSize="12">
                <MoreIcon />
                More
              </Text>
            </Link>
          </Center>
        </Pressable>
        {/* <Pressable cursor="pointer" opacity={selected === 3 ? 1 : 0.5} py="2" flex={1} onPress={() => setSelected(3)}>
                    <Center>
                        <Link to="/sign-up" style={{ textDecoration: 'none' }}>
                            <Text color="white" fontSize="12">
                            
                                Sign Up
                            </Text>
                        </Link>
                    </Center>
                </Pressable> */}
      </HStack>
    </Box>
  );
};

export default Tabs;
