import axios from "axios";
export const channelService = {
  channel,
};
const API = process.env.REACT_APP_API_URL;

async function channel(token, uid) {
  return await axios.get(`${API}/channels?server=${uid}`, {
    headers: { Authorization: "Bearer " + token },
  });
}
